@import 'styles/layers.scss';

@layer component {
  .content {
    height: 100%;
    padding-right: 20px;
    border-right: 1px solid var(--black11);
  }
  
  .title {
    margin-bottom: 10px;
  }
  
  .billets {
    row-gap: 20px;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
  
  .descr {
    margin-bottom: 15px;
  }

  .selectWrap {
    width: 300px;
    margin-bottom: 30px;
  }

  .text {
    margin-bottom: 30px;
  }
}