@import 'styles/layers.scss';

@layer component {
  .wrapper {
    display: flex;
    flex-direction: column;
    min-height: 141px;
    padding: 10px 12px 12px;
    border-radius: 4px;
    background-color: var(--black29);
  }
  
  .divider {
    margin: 8.12px 0 10.74px;
    background-color: var(--black12);
    opacity: 1;
  }
  
  .options-row {
    width: 100%;
  }

  .options-column {
    width: 33%;
  }
  
  .content {
    height: 100%;
  }
  
  .option {
    margin-bottom: 4px;
  }
}
